import { CdkDrag } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';

import { BehaviorSubject, of, Subject, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { BaseService } from '@nghedgehog/angular-ui';

import { environment } from '../../../../environments/environment';
import { FB_BadgeColor } from '../../../../initiator';

@Component({
  selector: 'app-fb-messenger',
  templateUrl: './fb-messenger.component.html',
  standalone: true,
  imports: [CommonModule, CdkDrag],
})
export class FbMessengerComponent {
  variant = input<'plain' | 'floating'>('floating');

  private _base = inject(BaseService);
  private hover$ = new Subject<boolean>();

  FB_BadgeColor = FB_BadgeColor;
  fbLink = `https://m.me/${environment.fbPageId}`;

  isDragging$ = new BehaviorSubject(false);

  showCloseButton$ = this.hover$.pipe(
    switchMap((isHovering) =>
      isHovering ? timer(300).pipe(map(() => true)) : of(false),
    ),
  );

  get fbLinkShow$() {
    return this._base.fbLinkShow$;
  }

  handleClick($event: MouseEvent) {
    $event.preventDefault();
    !this.isDragging$.value && window.open(this.fbLink, '_blank');
  }

  startHoverTimer() {
    this.hover$.next(true);
  }

  clearHoverTimer() {
    this.hover$.next(false);
  }

  toggleFbButton($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.fbLinkShow$.next(!this.fbLinkShow$.value);
  }

  handleDragStart() {
    this.isDragging$.next(true);
  }

  handleDragEnd() {
    requestAnimationFrame(() => {
      this.isDragging$.next(false);
    });
  }
}
