@import '_my-theme.variable';
@import '../../../../../../scss/animation/_underline.scss';

// https://codepen.io/philhoyt/pen/ujHzd

ul.choice-menu {
  position: relative;
  float: left;
  display: flex;

  li {
    position: relative;
    float: left;
    cursor: pointer;
    display: flex;
    align-items: center;

    a {
      display: block;
      padding: 1em;
      position: relative;
      white-space: nowrap;

      &:after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 80%;
        margin-right: 10%;
        height: 3px;
        background: white;
        opacity: 0;
        transform: translateX(-10px);
        transition: transform 0.3s, opacity 0.3s;
        pointer-events: none;
      }

      &.active,
      &:hover {
        &:after {
          opacity: 1;
          transform: translateX(0px);
        }
      }
    }

    &:hover {
      & > ul {
        display: block;
      }
    }

    ul {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      padding-bottom: 0.5rem;
      background-color: #c56600 !important;

      li {
        float: none;
      }

      ul {
        top: 0;
        left: 100%;
        background-color: #9e5200 !important;
      }
    }
  }
}

@screen lg-d {
  ul.choice-menu {
    background-color: rgba(165, 63, 0, 0.5);
    @apply elevation-24;
    overflow: auto;
    display: block;
    float: none;
    text-align: center;

    li {
      float: none;
      justify-content: center;
      flex-direction: column;

      ul {
        position: relative;

        ul {
          top: 0;
          left: auto;
        }
      }
    }
  }
}
