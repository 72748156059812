import { AsyncPipe } from '@angular/common';
import { Component, HostBinding, Input, inject } from '@angular/core';
import { RouterLink } from '@angular/router';

import {
  AuthService,
  I18nLinkPipe,
  SafePipe,
  ViewService,
} from '@nghedgehog/angular-ui';
import { TranslateModule } from '@ngx-translate/core';

import { constants } from '../../../../../environments/constants';
import { environment } from '../../../../../environments/environment';
import { MyIFrameComponent } from '../../../../core/components/my-iframe/my-iframe.component';
import { ImgPipe } from '../../../../core/pipe/img.pipe';
import { AboutInfoComponent } from '../about-info/about-info.component';
import { FooterLinkComponent } from './footer-link/footer-link.component';
import { input } from '@angular/core';
import { MenuModel } from '@alan-apps/data-access';
import { FbMessengerComponent } from '../../../../core/components/fb-messenger/fb-messenger.component';

@Component({
  selector: 'app-home-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    AboutInfoComponent,
    FooterLinkComponent,
    I18nLinkPipe,
    RouterLink,
    MyIFrameComponent,
    AsyncPipe,
    TranslateModule,
    SafePipe,
    ImgPipe,
    FbMessengerComponent,
  ],
})
export class FooterComponent {
  private _auth = inject(AuthService);
  private _view = inject(ViewService);
  @HostBinding('class') classList = 'block bg-dark';
  data = input<{ copyright: string }>();
  menus = input<MenuModel[]>();

  currentUser$ = this._auth.currentUser$;

  rootData = constants.rootData;
  businessEntity = constants.businessEntity;

  nowYear = new Date().getUTCFullYear();

  relationCompanies = this.rootData.relations;

  fbLink = `https://www.facebook.com/v15.0/plugins/page.php?adapt_container_width=true&app_id=113869198637480&channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df29bab6c769c288%26domain%3Ddevelopers.facebook.com%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fdevelopers.facebook.com%252Ff33697b5691514c%26relation%3Dparent.parent&container_width=734&height=72&hide_cover=false&href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D${environment.fbPageId}&locale=zh_TW&sdk=joey&show_facepile=true&small_header=true&tabs=timeline&width=270`;

  isPreview = environment.preview;

  isProduction = environment.production;

  goTop() {
    this._view.mainViewElm.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }
}
