import { CommonModule } from '@angular/common';
import {
  Component,
  HostBinding,
  inject,
  Injector,
  NgZone,
  PLATFORM_ID,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';

import {
  appGlobal,
  BaseService,
  CacheService,
  I18nService,
  InMemoryScrollService,
  LazyLoadErrorService,
  RouteLoadingService,
} from '@nghedgehog/angular-ui';
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { FbMessengerComponent } from './core/components/fb-messenger/fb-messenger.component';
import { PhotoGalleryComponent } from './core/components/my-gallery/photo-gallery/photo-gallery.component';
import { InlineStyleComponent } from './styles/inline-style/inline-style.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', './carousel.scss', './banner.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    LoadingBarModule,
    InlineStyleComponent,
    PhotoGalleryComponent,
    FbMessengerComponent,
  ],
})
export class AppComponent {
  private _viewContainerRef = inject(ViewContainerRef);
  private _base = inject(BaseService);
  private _i18n = inject(I18nService);
  public _routeLoading = inject(RouteLoadingService);
  private zone = inject(NgZone);
  private platformId = inject<any>(PLATFORM_ID);
  private _cache = inject(CacheService);
  private injector = inject(Injector);
  private _lazyLoadErrorService = inject(LazyLoadErrorService);
  @HostBinding('class') className = 'bg-gray-50 text-base';

  isBot = this._base.isBot;
  userAgent = navigator?.userAgent;
  language$ = this._i18n.language$;

  constructor() {
    inject(InMemoryScrollService);

    appGlobal.zone = this.zone;
    appGlobal.platformId = this.platformId;
    appGlobal.injector = this.injector;
    appGlobal.viewContainerRef = this._viewContainerRef;

    this._cache.setLocalFn(() => this._i18n.language);
    this._cache.checkAppVersion();
    this._lazyLoadErrorService.listen();

    window['testError'] = () => {
      throw new Error('test Error');
    };
  }
}
