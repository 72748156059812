@use 'sass:map';
@use '@angular/material' as mat;
@use 'sass:math';
@import '_my-theme.variable';

.mask-area {
  @apply min-h-[70vh] md:min-h-0 z-[1];
}

.backImg {
  box-shadow: 0 0 0 5000px #dc9122bf inset;
  // box-shadow: 0 0 0 5000px rgba(255, 255, 255, 0.3) inset;
  // background-attachment: fixed;
}

.properties {
  li + li {
    margin-left: 1em;
  }
}

.contact-info {
  > li {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
}

.outlet {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  &:not(.isHome) {
    > * {
      padding-top: $app-menu-height * $rate;
      display: block;
    }

    > router-outlet {
      display: none;
    }
  }
}

.qr {
  @apply w-[80px] md:w-[130px] aspect-square;
}
