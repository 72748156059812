<!-- link -->
<div class="container">
  <ul
    class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-center py-12 text-white"
  >
    @if (rootData.info; as info) {
      <li>
        <div class="flex justify-center">
          @if (info.bannerLogo; as bannerLogo) {
            <img
              [src]="bannerLogo | img | safe: 'url'"
              [alt]="info.title"
              [title]="info.title"
              class="rounded mb-4 h-16"
            />
          }
        </div>
        <h6 class="text-left mb-4">
          {{ info.description }}
        </h6>
        <app-about-info
          [businessEntity]="businessEntity"
          color="white inline-block"
        />
      </li>
    }
    <li class="text-left flex justify-center">
      <div>
        <h3 class="text-primary mb-4">
          {{ '網站地圖' | translate }}
        </h3>
        <!--  class="text-secondary" -->
        <nav>
          <ul class="grid grid-cols-2 gap-4">
            @for (menu of menus(); track menu.url) {
              <li footer-link [menu]="menu"></li>
            }
            <li>
              <a
                class="border border-primary text-white rounded hover-button p-1 transition cursor-pointer"
                [routerLink]="['/page/recruit'] | i18nLink"
              >
                {{ '人才招募' | translate }}
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <!-- <div>
      <h5>
        {{ '相關連結' | translate }}
      </h5>
      <nav>
        <ul>
          <li *ngFor="let menu of menus()" footer-link [menu]="menu" class="mb-4">
          </li>
          <li>
            <a class="border border-primary text-white rounded hover-button p-1 transition cursor-pointer">
              {{ '人才招募' | translate }}
            </a>
          </li>
        </ul>
      </nav>
    </div> -->
      <!--
    <h5 class="mt-12">
      {{ '相關連結' | translate }}
    </h5>
    <nav class="flex flex-col">
      <a *ngFor="let menu of menus()" [routerLink]="menu.url">{{menu.title}}</a>
    </nav> -->
    </li>
    <li>
      <div class="mb-6">
        <h3 class="mb-4 text-primary">
          {{ '推薦廠商' | translate }}
        </h3>
        <ul class="flex flex-wrap gap-4 justify-center">
          @for (company of relationCompanies; track company) {
            <li>
              <a [href]="company.link" target="_blank">
                <img
                  [src]="company.logo | img | safe: 'url'"
                  width="80"
                  [alt]="company.title"
                  class="rounded"
                />
              </a>
            </li>
          }
        </ul>
      </div>
      <div class="flex gap-2 items-center justify-center">
        @if (isProduction) {
          <my-iframe
            [src]="fbLink"
            height="72px"
            title="橘公寓 Facebook 粉絲團"
            width="270px"
            class="justify-center"
          />
        }
        <app-fb-messenger variant="plain" />
      </div>
    </li>
  </ul>
</div>

<!-- copyright -->
<footer class="copyright p-6 flex gap-4 items-center bg-primary elevation-24">
  <p class="m-0">
    Copyright © {{ nowYear }} {{ data().copyright }}. All rights reserved.
    @if (isPreview) {
      <span class="text-secondary">[preview mode]</span>
    }
  </p>
  <i class="flex-auto"></i>
  <a
    class="text-xs user-login cursor-pointer hover:underline hover:text-white"
    [routerLink]="['/member']"
  >
    @let user = currentUser$ | async;

    @if (!user) {
      {{ '經理人專區' | translate }}
    } @else {
      Hi!&nbsp;{{ user.name }} {{ '經理中心' | translate }}
    }
  </a>
</footer>
