<ngx-loading-bar color="#29b6f6" />
<router-outlet />

<app-inline-style />
<app-photo-gallery />
<div hidden>
  isBot: {{ isBot }} <br />
  userAgent: {{ userAgent }} <br />
  language: {{ language$ | async }} <br />
</div>

<!--
in safari mobile, fb have x-frame-options' to 'deny' issue
https://stackoverflow.com/questions/27358966/how-to-set-x-frame-options-on-iframe
-->

<app-fb-messenger />
