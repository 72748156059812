.carousel {
  .content {
    display: flex;

    .item {
      width: 100%;
      display: block;
    }
  }
}

.img {
  @apply aspect-square;

  .social-media {
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transform: translateY(100%);

    a + a {
      margin-left: 0.5em;
    }
  }

  &:hover {
    .social-media {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
