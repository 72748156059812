@import '_my-theme.variable';

app-index-carousel {
  .backImg {
    // box-shadow: 0 0 0 5000px rgba(75, 75, 75, 0.5) inset;
    box-shadow: none;
  }

  // next float button
  .bottom {
    bottom: 0;
    left: 0;
  }

  .next-animation {
    animation: nextAni 2s linear infinite;
  }

  @keyframes nextAni {
    0% {
    }

    50% {
      transform: translateY(5px);
    }

    100% {
    }
  }

  .resize-area {
    width: 100%;
  }

  @screen md {
    .resize-area {
      width: 60% !important;
    }
  }

  .text-shadow {
    text-shadow: 0px 0px 5px #aaa;

    @screen md {
      text-shadow: 4px 4px 4px #aaa;
    }
  }

  // override mui style
  .custom .mdc-button__label {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
