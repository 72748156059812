// Define theme
@forward 'sass:map';

$app-menu-height: 120px;
$rate: 0.6;

$active-color: #ffa748;
$app-border-color: #aeaeae;

@mixin flex_width($width) {
  flex: 1 1 $width;

  @if $width !=auto {
    max-width: $width;
    min-width: $width;
  } @else {
    max-width: initial;
    min-width: initial;
  }
}
