@import '_my-theme.variable';
@import '../../../../../scss/animation/_underline.scss';
$rate: 0.6;

app-home-menu {
  @apply z-[5];

  .menu-container {
    height: $app-menu-height;

    img {
      height: 60px;
    }

    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: $app-menu-height * $rate;

      .title {
        font-size: 1.8rem !important;
      }

      img {
        height: ($app-menu-height * $rate) - 10px;
      }
    }

    &.notHome {
      height: $app-menu-height * $rate;
      @apply elevation-1;

      .title {
        font-size: 1.8rem !important;
      }

      img {
        height: ($app-menu-height * $rate) - 10px;
      }
    }

    &.small-menu {
      transform: translateY(-100%);
    }
  }
}

.hover-button {
  &:hover {
    @apply bg-primary;
    color: white !important;
  }
}

app-home-menu {
  .menu-container {
    &.notHome {
      @apply bg-primary;
    }
  }
}

@screen lg-d {
  app-home-menu {
    .navbar {
      position: fixed;
      z-index: 2;
      visibility: hidden;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      box-shadow: 5px 0 25px rgba(0, 0, 0, 0.25);
      background: rgba(0, 0, 0, 0.5);

      .navbar-box {
        transform: translateX(-100%);

        ul {
          // background:
          flex-direction: column;
          height: 100%;
          width: 248px;
        }
      }

      &.active {
        visibility: visible;

        .navbar-box {
          transform: translateX(0);
        }
      }
    }
  }
  app-home-menu {
    .navbar {
      .navbar-box {
        @apply bg-primary;
      }
    }
  }
}
